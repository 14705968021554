<template>
  <b-container class="align-middle m-auto w-100 page d-flex justify-content-center align-items-center">
    <template>
      <b-row v-if="doneLoadingNftMeta">
        <b-col  v-for="sub in 6" :key="sub" cols="3" >
          <NftItem
            :appear-clickable="appearClickable(sub)"
            :sub="sub"
            :holding="holdings[sub]"
            :img="imgMappings[sub]"
            :meta="nftMetaDetails[sub]"
            @click="showModal(sub)"
          />
        </b-col>
      </b-row>
      <b-modal v-model="showTransferModal" centered class="custom-backdrop" :title="`GIO #${selectedToken}`">
        <b-form id="transfer-form" @submit="onSubmit" @reset="onReset">
          <b-form-group id="fg-1" label="To Wallet Address" label-for="wallet-address">
            <b-form-input
              id="wallet-address"
              v-model="form.toAddress"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
  
          <b-form-group id="fg-2" label="Amount To Transfer" label-for="amount">
            <b-form-spinbutton id="amount" v-model="form.amount" min="1" :max="holdings[selectedToken]"></b-form-spinbutton>
          </b-form-group>
          <b-alert :show="transferring" variant="info">Please check your wallet to confirm transactions</b-alert>
          <b-alert :show="transferring && !!txHash" variant="info">
            Processing...
            <b-link :href="`https://gioexplorer.com/tx/${txHash}`" target="_blank">View on explorer</b-link>
          </b-alert>
          <b-alert :show="showSuccessMessage" variant="success">
            Successfully Transferred <br>
            <b-link :href="`https://gioexplorer.com/tx/${txHash}`" target="_blank">View on explorer</b-link>
          </b-alert>
          <b-alert :show="!!errorMessage" variant="danger" class="overflow-auto">
            {{ errorMessage }} <br>
          </b-alert>
        </b-form>
        <template #modal-footer>
          <div class="w-100">
            <div class="float-right">
              <b-button form="transfer-form" type="submit" variant="primary" :disabled="transferring">
                <b-spinner v-if="transferring" small type="grow"></b-spinner>
                Transfer
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </template>
  </b-container>
</template>
  
<script setup>
import { inject, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { watchAccount, getAccount, waitForTransactionReceipt } from '@wagmi/core'
import { getAccountHoldings, transferToken, getNftsMintingInfo } from '../contractInterfacesNew/gio-node'
import NftItem from '../components/holder/NftItem.vue'
import { getNodeAddress } from "@/api/node"

import nft1Img from '@/assets/GioBasic.png'
import nft2Img from '@/assets/GioBasic+.png'
import nft3Img from '@/assets/GioPremium.png'
import nft4Img from '@/assets/GioPremium+.png'
import nft5Img from '@/assets/GioMaster.png'
import nft6Img from '@/assets/GioMaster+.png'

const route = useRoute()

const wagmiConfig = inject('wagmiConfig')

const account = ref({})

const showTransferModal = ref(false)

const form = ref({
  toAddress: '',
  amount: 1,
})

const imgMappings = {
  1: nft1Img,
  2: nft2Img,
  3: nft3Img,
  4: nft4Img,
  5: nft5Img,
  6: nft6Img,
}

const holdings = ref({})
const selectedToken = ref(null)
const walletConnectAddress = ref('')
const transferring = ref(false)
const showSuccessMessage = ref(false)
const errorMessage = ref('')
const txHash = ref('')
const nftMetaDetails = ref({})
const doneLoadingNftMeta = ref(false)

const appearClickable = (tokenId) => {
  return (walletConnectAddress.value || account.value.address) && (walletConnectAddress.value || account.value.address) === route.params?.address && isTransferrable(tokenId)
}

const isTransferrable = (tokenId) => {
  const account = getAccount(wagmiConfig)
  if (account?.address !== route.params?.address) return false
  if (holdings.value[tokenId] <= 0) return false

  return true
}

const showModal = (tokenId) => {
  if (!isTransferrable(tokenId)) return

  showSuccessMessage.value = false
  errorMessage.value = ''
  form.value.toAddress = ''
  form.value.amount = 1

  selectedToken.value = tokenId
  showTransferModal.value = true
}

const onSubmit = async (event) => {
  event.preventDefault()
  try {
    txHash.value = ''
    transferring.value = true
    showSuccessMessage.value = false
    errorMessage.value = ''

    txHash.value = await transferToken(form.value.toAddress, selectedToken.value, form.value.amount)
    
    const transactionReceipt = await waitForTransactionReceipt(wagmiConfig, {
      hash: txHash.value,
    })

    if (transactionReceipt.status === 'success') {
      showSuccessMessage.value = true
      getHoldings()
    } else {
      throw new Error('Transaction reverted')
    }
  } catch (error) {
    errorMessage.value = error.message
  } finally {
    transferring.value = false
  }
}

const onReset = (event) => {
  event?.preventDefault()
  form.value.toAddress = ''
  form.value.amount = 1
}

const getHoldings = async () => {
  holdings.value = await getAccountHoldings(route.params?.address)
}

const getNftMetaDetails = async () => {
  doneLoadingNftMeta.value = false
  nftMetaDetails.value = await getNftsMintingInfo(route.params?.address)
  const resp = await getNodeAddress();

  Object.keys(nftMetaDetails.value).forEach((key) => {
    nftMetaDetails.value[key]['ipAddress'] = resp?.ipAddress || '134.122.124.171'
  })

  doneLoadingNftMeta.value = true
}

watchAccount(wagmiConfig, {
  onChange(data) {
    if (data.address) {
      walletConnectAddress.value = data.address
    } else {
      account.value = {}
      walletConnectAddress.value = ''
    }
  },
})

onMounted(() => {
  account.value = getAccount(wagmiConfig)
  getHoldings()
  getNftMetaDetails()
})
</script>

<style scoped>
.page {
  min-height: 90vh;
}

#__BVID__64___BV_modal_backdrop_ {
  background-color: #5c34a2;
}

.custom-backdrop .modal-backdrop {
  background-color: #5c34a2;
}
</style>