import { readContract, writeContract, getBlock, getAccount, getClient } from '@wagmi/core'
import { inject, ref } from 'vue'
import { formatEther } from 'viem'
import { getLogs } from 'viem/actions'
import { parseAbiItem } from 'viem'
import contractJSON from '../../contracts/GIONode.json'

const contractAddress = contractJSON.address

const abi = contractJSON.abi

const savedInjectedWagmiConfig = ref(null);

export const setupWagmiConfig = async () => {
  if (!savedInjectedWagmiConfig.value) {
    const wagmiConfig = inject('wagmiConfig')
  
    savedInjectedWagmiConfig.value = wagmiConfig
  }
}

export const getAccountHoldings = async (address) => {

  if (!savedInjectedWagmiConfig.value) {
    setupWagmiConfig()
  }

  const holdings = {}

  Array.from({length: 6}, (x, i) => i + 1).forEach((tokenId) => {
    const result = readContract(savedInjectedWagmiConfig.value, {
      abi,
      address: contractAddress,
      functionName: 'balanceOf', 
      args: [address, tokenId],
    })
    holdings[tokenId] = result
  })

  const responses = await Promise.all(Object.values(holdings))
  
  responses.forEach((val, i) => {
    holdings[i + 1] = Number(val)
  })

  return holdings
}

export const transferToken = async (toAddress, sub, amount) => {
  if (!savedInjectedWagmiConfig.value) {
    setupWagmiConfig()
  }
  return writeContract(savedInjectedWagmiConfig.value, {
    abi,
    address: contractAddress,
    functionName: 'transferToken',
    args: [
      sub,
      amount,
      toAddress,
    ],
  })
}

export const getClaimableAmount = async (address) => {

  if (!savedInjectedWagmiConfig.value) {
    setupWagmiConfig()
  }
  const result = await readContract(savedInjectedWagmiConfig.value, {
    abi,
    address: contractAddress,
    functionName: 'getClaimableAmount', 
    args: [address],
  })

  return formatEther(result)
}

export const claimProfit = async () => {
  const account = getAccount(savedInjectedWagmiConfig.value)
  if (!account.address) return

  if (!savedInjectedWagmiConfig.value) {
    setupWagmiConfig()
  }

  return writeContract(savedInjectedWagmiConfig.value, {
    abi,
    address: contractAddress,
    functionName: 'claim',
    account: account.address,
  })
}

export const getNftsMintingInfo = async (address = '0x25dDD58F18ad694c5C788BbA1DdA47399d213201') => {

  if (!savedInjectedWagmiConfig.value) {
    setupWagmiConfig()
  }

  const publicClient = getClient(savedInjectedWagmiConfig.value)

  const logs = await getLogs(publicClient, {
    address: contractAddress,
    event: parseAbiItem('event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value)'),
    args: {
      from: '0x0000000000000000000000000000000000000000',
      to: address,
    },
    fromBlock: 'earliest',
    toBlock: 'latest'
  })

  /**
   * Format will be like
   * {
   *  1: { transactionHash: 0x221..., blockHash: 0xw32, minted_at: ..., },
   *  1: { transactionHash: 0x121..., blockHash: 0x232, minted_at: ..., },
   * }
   */
  let transactionHashesForFirstGIOs = {}

  for (const logIndex in logs) {
    if (Object.keys(transactionHashesForFirstGIOs).length === 6) break

    const gioId = Number(logs[logIndex].args.id)
    if (transactionHashesForFirstGIOs[gioId]) continue

    transactionHashesForFirstGIOs[gioId] = {
      blockHash: logs[logIndex].blockHash,
      transactionHash: logs[logIndex].transactionHash,
    }
  }

  for (const key in transactionHashesForFirstGIOs) {
    const block = await getBlock(savedInjectedWagmiConfig.value, {
      blockHash: transactionHashesForFirstGIOs[key].blockHash,
    })

    transactionHashesForFirstGIOs[key]['minted_at'] = new Date(Number(block.timestamp) * 1000).toDateString()
  }

  return transactionHashesForFirstGIOs
}